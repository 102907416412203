import React, { Suspense, useEffect } from "react";
import { useRoutes } from "react-router-dom";

// layouts
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import HorizontalLayout from "../layouts/Horizontal";

// components
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";

// constants
import { LayoutTypes } from "../constants";

// hooks
import { useRedux } from "../hooks";
import Loading from "../components/Loading";

// lazy load all the views
// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));

// Other pages
const Error404 = React.lazy(() => import("../pages/other/Error404"));
const Error500 = React.lazy(() => import("../pages/other/Error500"));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));

// Admin Routes
const Users = React.lazy(() => import("../pages/admin/Users"));
const AddUser = React.lazy(() => import("../pages/admin/Users/AddUser"));
const Files = React.lazy(() => import("../pages/admin/Files"));
const ViewFile = React.lazy(() => import("../pages/admin/Files/ViewFile"));
const SubscriptionPlans = React.lazy(() => import("../pages/admin/Plans"));
const AddPlan = React.lazy(() => import("../pages/admin/Plans/AddPlan"));
const UserSusbcription = React.lazy(
  () => import("../pages/admin/Subscriptions")
);
const AddUserSubs = React.lazy(() => import("../pages/admin/Subscriptions/addUserSubs"));
const SendNotification = React.lazy(() => import("../pages/admin/Notification/SendNotification"));
const Questions = React.lazy(() => import("../pages/admin/Questions"));
const viewQuestion = React.lazy(() => import("../pages/admin/Questions/viewQuestion"));
const Payout = React.lazy(() => import("../pages/admin/Payout"));
const ViewPayout = React.lazy(() => import("../pages/admin/Payout/viewPayout"));

const Dashboard = React.lazy(() => import("../pages/admin/Dashboard"));
const Profile = React.lazy(() => import("../pages/admin/Profile"));

const loading = () => <Loading />;

type LoadComponentProps = {
  component: React.LazyExoticComponent<(props: any | null) => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  const { appSelector } = useRedux();

  const { layout } = appSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls: React.ComponentType = VerticalLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();

  const { dispatch } = useRedux();

  const getLoggedInUser = () => {
    dispatch({ type: "GET_LOGGED_IN_USER" });
  };
  useEffect(() => {
    getLoggedInUser();
  }, []);

  return useRoutes([
    { path: "/", element: <Root /> },
    {
      // public routes
      path: "/",
      element: <DefaultLayout />,
      children: [
        {
          path: "auth",
          children: [
            { path: "login", element: <LoadComponent component={Login} /> },
            {
              path: "register",
              element: <LoadComponent component={Register} />,
            },
            { path: "confirm", element: <LoadComponent component={Confirm} /> },
            {
              path: "forget-password",
              element: <LoadComponent component={ForgetPassword} />,
            },
            {
              path: "lock-screen",
              element: <LoadComponent component={LockScreen} />,
            },
            { path: "logout", element: <LoadComponent component={Logout} /> },
          ],
        },
        {
          path: "*",
          element: <LoadComponent component={Error404} />,
        },
        {
          path: "500",
          element: <LoadComponent component={Error500} />,
        },
        {
          path: "maintenance",
          element: <LoadComponent component={Maintenance} />,
        },
      ],
    },
    {
      // auth protected routes
      path: "/",
      element: <PrivateRoute roles={"Admin"} component={Layout} />,
      children: [
        {
          path: "dashboard",
          element: <LoadComponent component={Dashboard} />,
        },
        {
          path: "profile",
          element: <LoadComponent component={Profile} />,
        },
        {
          path: "/users",
          children: [
            {
              path: "",
              element: <LoadComponent component={Users} />,
            },
            {
              path: "create",
              element: <LoadComponent component={AddUser} />,
            },
            {
              path: ":id/edit",
              element: <LoadComponent component={AddUser} />,
            },
          ],
        },
        {
          path: "/plans",
          children: [
            {
              path: "",
              element: <LoadComponent component={SubscriptionPlans} />,
            },
            {
              path: "create",
              element: <LoadComponent component={AddPlan} />,
            },
            {
              path: ":id/edit",
              element: <LoadComponent component={AddPlan} />,
            },
          ],
        },

        {
          path: "/files",
          children: [
            {
              path: "",
              element: <LoadComponent component={Files} />,
            },

            {
              path: ":id/view",
              element: <LoadComponent component={ViewFile} />,
            },
          ],
        },

        {
          path: "/users-susbcription",
          children: [
            {
              path: "",
              element: <LoadComponent component={UserSusbcription} />,
            },
            {
              path: ":id/edit",
              element: <LoadComponent component={AddUserSubs} />,
            },
            // {
            //   path: ":id/details",
            //   element: <LoadComponent component={DriversDetails} />,
            // },
          ],
        },

        {
          path: "/payout",
          children: [
            {
              path: "",
              element: <LoadComponent component={Payout} />,
            },
            {
              path: ":id/view",
              element: <LoadComponent component={ViewPayout} />,
            },
            // {
            //   path: ":id/details",
            //   element: <LoadComponent component={DriversDetails} />,
            // },
          ],
        },

        {
          path: "/send-notification",
          children: [
            // {
            //   path: "",
            //   element: <LoadComponent component={UserSusbcription} />,
            // },
            {
              path: "create",
              element: <LoadComponent component={SendNotification} />,
            },
            // {
            //   path: ":id/details",
            //   element: <LoadComponent component={DriversDetails} />,
            // },
          ],
        },

        //Questions 
        {
          path: "/questions",
          children: [
            {
              path: "",
              element: <LoadComponent component={Questions} />,
            },
            {
              path: ":id",
              element: <LoadComponent component={viewQuestion} />,
            },
            
          ],
        },
      ],
    },
  ]);
};

export { AllRoutes };
